import { Transition } from "@headlessui/react";
import clsx from "clsx";
import type { FC } from "react";

interface ModalProps {
  show: boolean;
  close?: () => void;
  wide?: boolean;
  zIndexClass?: string;
}

const Modal: FC<ModalProps> = (props) => {
  return (
    <Transition<"div">
      className={clsx("fixed inset-0 overflow-y-auto", props.zIndexClass ?? "z-10")}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={props.show}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 pb-20 text-center sm:px-4 sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={props.close}></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <Transition.Child<"div">
          aria-labelledby="modal-headline"
          aria-modal="true"
          className={clsx(
            "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full mx-2 sm:mx-0 sm:p-6",
            props.wide ? "sm:max-w-2xl md:max-w-5xl lg:max-w-7xl" : "sm:max-w-xl"
          )}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          role="dialog"
        >
          {props.children}
        </Transition.Child>
      </div>
    </Transition>
  );
};
export default Modal;
