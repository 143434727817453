import clsx from "clsx";
import type { FC, ReactElement } from "react";
import Modal from "./Modal";

interface SimpleModalProps {
  show: boolean;
  close?: () => void;
  buttonClicked?: () => void;
  icon?: ReactElement;
  title?: string;
  body?: string;
  buttonText?: string;
  iconClass?: string;
  zIndexClass?: string;
}

const SimpleModal: FC<SimpleModalProps> = (props) => {
  return (
    <Modal close={props.close} show={props.show} zIndexClass={props.zIndexClass}>
      <div>
        {props.icon ? (
          <div
            className={clsx(
              "mx-auto flex items-center justify-center h-12 w-12 rounded-full",
              props.iconClass
            )}
          >
            {props.icon}
          </div>
        ) : null}
        <div className="my-3 text-center sm:my-5">
          {props.title && (
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
              {props.title}
            </h3>
          )}
          {props.body && (
            <div className="mt-2">
              <p className="text-sm leading-5 text-gray-500">{props.body}</p>
            </div>
          )}
          {props.children}
        </div>
      </div>
      {props.buttonText && (
        <div className="mt-5 sm:mt-6">
          <span className="flex w-full rounded-md shadow-sm">
            <button
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:ring-primary-500 sm:text-sm sm:leading-5"
              onClick={props.buttonClicked}
              type="button"
            >
              {props.buttonText}
            </button>
          </span>
        </div>
      )}
    </Modal>
  );
};
export default SimpleModal;
