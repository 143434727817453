import clsx from "clsx";
import type { FC } from "react";

const ConstrainedContainer: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div className="py-6">
      <div className={clsx("mx-auto max-w-7xl sm:px-6 lg:px-8", className)}>{children}</div>
    </div>
  );
};

export default ConstrainedContainer;
