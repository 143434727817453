import { createContext, useContext } from "react";
import type Auth from "../types/interfaces/user";
import type Language from "../types/language";

export interface AuthState {
  language: Language;
  serverTimeOffset: number;
  auth?: Auth | null;
  revalidate: () => void;
}

const AuthContext = createContext<AuthState>({
  revalidate: () => {
    return;
  },
  serverTimeOffset: 180,
  language: "tr",
});

export default AuthContext;

export const useAuth = (): AuthState => {
  return useContext(AuthContext);
};
