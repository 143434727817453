import type { FC } from "react";
import { lazy, memo, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FoldingCubeLoader from "./components/FoldingCubeLoader";
import AuthContext from "./context/AuthContext";
import PrivateRoute from "./routing/PrivateRoute";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import type Auth from "./types/interfaces/user";
import type Language from "./types/language";

const Login = lazy(() => import("./layout/login"));
const MainLayout = lazy(() => import("./layout/main"));
const Signout = lazy(() => import("./layout/signout"));

interface AppContainerProps {
  auth: Auth | null | undefined;
  revalidate: () => void;
  language: Language;
  serverTimeOffset: number;
}

const AppContainer: FC<AppContainerProps> = (props) => {
  if (props.auth !== undefined) {
    return (
      <HelmetProvider>
        <AuthContext.Provider value={props}>
          <ServiceWorkerWrapper />
          <Helmet>
            <html lang={props.language} />
          </Helmet>
          <BrowserRouter>
            <Suspense fallback={<FoldingCubeLoader />}>
              <Switch>
                <Route component={Login} path="/login" />
                <PrivateRoute component={Signout} path="/signout" />
                <PrivateRoute component={MainLayout} path="*" />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </AuthContext.Provider>
      </HelmetProvider>
    );
  }
  return <FoldingCubeLoader />;
};

export default memo(AppContainer);
