import { ErrorBoundary } from "@sentry/react";
import type { ComponentClass, ReactElement } from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import ConstrainedContainer from "../components/containers/ConstrainedContainer";
import { useAuth } from "../context/AuthContext";
import ErrorFallback from "../ErrorFallback";

function PrivateRouteWithProps<PropType>({
  component: Component,
  constrained,
  props: componentProps,
  roles,
  ...rest
}: RouteProps & {
  props: PropType;
  roles?: string[];
  constrained?: boolean;
}): ReactElement<PropType> {
  const RoutedComponent = Component as ComponentClass<PropType>;

  let redirectPath = rest.location?.pathname;

  if (redirectPath === "/signout") redirectPath = "/";

  const { auth } = useAuth();

  return (
    <Route
      {...rest}
      render={(routeProps): ReactElement => {
        if (
          auth?.user.email !== undefined &&
          (roles === undefined || roles.some((role) => auth.roles?.includes(role)))
        ) {
          if (constrained) {
            return (
              <ConstrainedContainer>
                <ErrorBoundary fallback={<ErrorFallback />} showDialog>
                  <RoutedComponent {...routeProps} {...componentProps} />
                </ErrorBoundary>
              </ConstrainedContainer>
            );
          }
          return (
            <ErrorBoundary fallback={<ErrorFallback />} showDialog>
              <RoutedComponent {...routeProps} {...componentProps} />
            </ErrorBoundary>
          );
        }
        if (
          auth?.user.email !== undefined &&
          roles !== undefined &&
          roles.every((role) => !auth.roles?.includes(role))
        )
          return <Redirect to="/Unauthorized" />;

        return (
          <Redirect
            to={`/login?redirect=${redirectPath ?? ""}${
              rest.location?.search ? `&${rest.location.search.slice(1)}` : ""
            }`}
          />
        );
      }}
    />
  );
}

export default PrivateRouteWithProps;
