/* eslint-disable max-lines */
import type LocaleStrings from "./localeStrings";

const tr: LocaleStrings = {
  Active: "İşlem Görüyor",
  Finished: "Tamamlanmış",
  Pending: "Beklemede",
  abstain: "Pas",
  accept: "Kabul Et",
  acceptedCandidates: "Desteklediğiniz Adaylar",
  accountStatement: "Hesap Hareketleri",
  activate: "Aktive Et",
  activateAnnouncement: "Duyuruyu Aktive Et",
  active: "İşlem Görüyor",
  activeRemoteEmployees: "Aktif Uzaktan Çalışanlar",
  add: "Ekle",
  addCategory: "Kategori Ekle",
  addColumn: "Yeni Kategori Girişi",
  addData: "Yeni Veri Girişi",
  addEmployee: "Çalışan Ekle",
  addItem: "{0} Ekle",
  addNewClause: "Yeni Madde Ekle",
  addNewCustomerCase: "Yeni Müşteri Vakası Oluştur",
  addNewPurchaseRDRequest: "Yeni Satın Alma Arge Talebi Oluştur",
  addNewPurchaseRequest: "Yeni Satın Alma Talebi Oluştur",
  addNewRole: "Yeni Görev Tanımı Ekle",
  addNewServiceRequest: "Yeni İç Hizmet Talebi Oluştur",
  addNewSoftwareRequest: "Yeni Yazılım Geliştirme Talebi Oluştur",
  addNote: "Not Ekle",
  allFunds: "TÜM FONLAR",
  allProducts: "Tüm Ürünler",
  allStores: "Tüm Mağazalar",
  amount: "Miktar",
  and: " ve ",
  announcements: "Duyurular",
  approve: "Kabul Et",
  askAnswerQuestionsHere: "Sorularınızı aşağıdaki formu kullanarak gönderebilirsiniz",
  askQuestion: "Soruyu Gönder",
  assignJob: "Talebi Ata",
  assignJobDescription: "Lütfen talebi atayacağınız çalışanı seçiniz",
  attempted_delivery: "Başarısız Teslimat Denemesi",
  availableIncome: "Hesap Bakiyesi",
  averagePrice: "Ortalama Ürün Fiyatı",
  averageWeightPrice: "Ortalama Kg Fiyatı",
  awaitedAtDate: "{0} tarihinde bekleniyor",
  awaitingApproval: "Onay Bekliyor",
  awaitingClauses: "Bekleyen Maddeler",
  awaitingPayment: "Ödeme bekleyen siparişler: {0}",
  basedOnLastYearData: "Önceki yılın verilerine göre",
  basisDays: "Hesaplamaya Esas Gün",
  basisSalary: "Kıdem Tazminatına Esas Maaş",
  beACandidate: "Aday Ol",
  benefitsForUser: "{0} adlı çalışana ait Kıdem ve İhbar Tazminatları",
  birthdayMessage: "{0} bugün {1}. Kutlamalara sen de katıl!",
  birthdays: "Doğum Günleri",
  bloodType: "Kan Grubu",
  bonus: "Prim",
  bornToday: "doğdu",
  bundle: "Bundle",
  call: "Ara",
  cancel: "İptal Et",
  cancelRequest: "Talebi İptal Et",
  cancelWarning: "Talebinizi iptal ederseniz ürünle alakalı tüm önceliklerinizi kaybedeceksiniz.",
  category: "Kategori",
  category_bundleFinder:
    "Çeşitli kısımlardan yönlendirme yaptığımız ve bundle detay sayfası olarak kullandığımız kısımlardan yapılan satışlar.",
  category_checkbox:
    "Ürün veya bundle kısımlarında yer alan ve birlikte satın alınmasını önerdiğimiz ürünler kısmından yapılan satışlar.",
  category_collection:
    "Product menüsü içerisinden veya header altındaki iconlara tıklanarak ulaşılan sayfalardan yapılan satışlar.",
  category_compare:
    "Ürün karşılaştırılması ile istenilen bundle'a ulaşılan sayfa üzerinden yapılan satışlar.",
  category_compatibleProduct:
    "Ürün detay sayfalarının altında yer alan kısımlardan yapılan satışlar.",
  category_discount: "Kampanya detay sayfaları üzerinden yapılan satışlar.",
  category_home: "Anasayfa üzerinden yapılan satışlar.",
  category_product:
    "Tüm ürün sayfalarının normal akışlarında yer alan Buy Now kısımlarından yapılan satışlar.",
  category_productModal:
    "Birden fazla modeli bulunan ürünlerin overview sayfalarından veya bazı video looplar üzerinden sepete ürün eklemeyi sağlayan alanlardan yapılan satışlar.",
  category_recommendedProduct:
    "Shopping Cart kısmında yer alan ürün veya bundle ile birlikte satın alınmasını önerdiğimiz ürünler kısmından yapılan satışlar.",
  category_search: "Arama sayfası üzerinden yapılan satışlar.",
  category_selectbox: "Ürün veya bundle'ların zorunlu seçim alanlarından yapılan satışlar.",
  category_tradeup: "TradeUP sayfası üzerinden yapılan satışlar",
  chart: "Grafik",
  checkIn: "Teslim Al",
  checkInProduct: "Ürün Teslim Alma",
  checkOut: "Teslim Et",
  checkOutProduct: "Ürün Teslim Formu",
  checkoutDate: "Teslim Tarihi",
  chooseCandidates: "Lütfen oy vermek istediğiniz adayları seçiniz.",
  chooseCandidatesForEachCategory:
    "Lütfen her kategori için o kategoride beklenti ötesi başarı gösterdiğini düşündüğünüz çalışanları seçiniz.",
  chooseCase: "Başlamak için yandaki listeden bir vaka seçiniz",
  chooseCategories: "Başlamak için aşağıdaki tablodan bir kategori seçiniz",
  chooseEmployeeToAdd: "Lütfen bu görev tanımına eklemek istediğiniz çalışanı seçiniz",
  chooseEmployeeToRemove: "Lütfen bu görev tanımından çıkarmak istediğiniz çalışanı seçiniz",
  chooseProducts: "Başlamak için aşağıdaki tablodan bir ürün seçiniz",
  clickHereToVoteNow: "Oy vermek için buraya tıklayınız.",
  coating: "Kaplama",
  commandOverJob: "İşinin Bilimine Hakimlik",
  comparedTo: "{0} ile karşılaştırıldığında",
  comparedToLastYear: "(Önceki yıla göre)",
  comparison: "Karşılaştırma",
  compromising: "Uzlaşıcılık",
  confirmed: "Yola Çıkmayı Bekliyor",
  contactAdministrationIfMistake:
    "Eğer bir hata olduğunu düşünüyorsanız, lütfen yönetim ile iletişime geçiniz.",
  contactAdministrator: "Lütfen site yöneticisiyle iletişime geçiniz.",
  createRequestForProduct: "Bu Ürünü Talep Et",
  createdOn: "Talep Tarihi",
  currentMonthRevenue: "Mevcut Ayın Cirosu",
  currentSalary: "Mevcut Brüt Maaşı",
  currentYearFulfillment: "Bu Yıl Gönderilen",
  currentYearSales: "Bu Yıl Satılan",
  customerEmail: "Müşteri Emaili",
  customerFeedback: "Müşteri Geri Dönüşleri",
  customerList: "Müşteri Listesi",
  customerOrders: "Müşterinin Siparişleri",
  customerProducts: "Müşteriye Ait Ürünler",
  customerSatisfaction: "Müşteri Memnuniyeti",
  daily: "Günlük",
  dailyAverage: "Günlük Ortalama",
  dailySalary: "Günlük Brüt Maaş",
  dailyTarget: "Günlük Hedef",
  date: "Tarih",
  day: "gün",
  days: "gün",
  daysOfWeek: ["Pzt", "Sal", "Çrş", "Prş", "Cum", "Cmt", "Pzr"],
  daysUntilArrival: "Kaç Gün Kaldı",
  deactivate: "Deaktive Et",
  deactivateAnnouncement: "Duyuruyu Deaktive Et",
  deductAmount: "Mahsup Et",
  deductFromSalaryFund: "Maaş Fonundan Mahsup Et",
  deductionExplanation: "Lütfen maaş fonunda mahsup edilen miktarı giriniz",
  delete: "Sil",
  deleteClause: "Maddeyi Kaldır",
  deleteRecord: "Bu Kaydı Sistemden Sil",
  deleteRecordButton: "{0} Sil",
  deleteWarning: "Bu kaydı sildiğiniz takdirde, kayıt ile alakalı tüm bilgiler de silinecektir.",
  delivered: "Teslim Edildi",
  deliveredCount: "Teslim Edilen Ürün Adedi",
  departmentAdmin: "Yönetici",
  departmentName: "Departman Adı",
  description: "Açıklama",
  details: "Detaylar",
  director: "Direktör",
  discounts: "İndirimler",
  discountsAndRefunds: "{0} İndirim ve İadeleri",
  dontBeACandidate: "Aday Olma",
  downloadFile: "Dosyayı indir",
  dragDrop: "ya da buraya sürükleyip bırakın",
  drop: "Dosyayı buraya bırakabilirsiniz",
  edit: "Düzenle",
  editDeadline: "Planlanan Tarihi Düzenle",
  editItem: "{0} Düzenle",
  editMyProfile: "Profilimi Düzenle",
  emergencyContact: "Acil Durumda Ulaşılacak Kişi",
  emergencyContactPhoneNumber: "Acil Durum Telefon Numarası",
  employee: "Çalışan",
  employeeName: "Çalışan Adı",
  endShift: "Çalışmayı Bitir",
  enterBankRecordDetails: "Lütfen banka kaydına ait detayları doldurunuz.",
  enterDetails: "Lütfen {0} ile ilgili tüm detayları giriniz.",
  enterDetailsForNew: "Lütfen yeni {0} ile ilgili tüm detayları giriniz.",
  entranceDate: "İşe Giriş Tarihi",
  entranceText: "Sunum Yazıları",
  error: "Hata",
  errorTitle: "Girdiğiniz bilgilerde 1 hata var.",
  errorsTitle: "Girdiğiniz bilgilerde {0} hata var.",
  estimated: "Yaklaşık",
  evaluateCandidates:
    "Lütfen kişilerin raporlarını okuyarak her çalışanın büyümeye olan katkısını 0-100 arasında değerlendiriniz.",
  exitDate: "İşten Ayrılma Tarihi",
  expectedReturnDate: "İade Edileceği Tarih",
  experience: "Deneyimleri",
  exportToExcel: "Excel dosyası olarak indir",
  extendLoan: "Süreyi Uzat",
  extendedLoan: "Süre Uzatılmış",
  externalParts: "Dış Parçalar",
  extraCost: "Diğer Maliyetler",
  factoryProductName: "Üretim Ürün Adı",
  failure: "Başarısız",
  fileNotFound: "Dosya bulunamadı",
  fillInPaymentDetails: "Lütfen ödemeyle ilgili tüm bilgileri doldurunuz",
  finish: "Talebi Sonlandır",
  finishDescription: "Lütfen talep ile ilgili detayları doldurunuz",
  finishPurchase: "Satın Almayı Sonlandır",
  finishPurchaseDescription: "Lütfen satın alma ile ilgili detayları doldurunuz",
  finishRequest: "Talebi Sonlandır",
  finishRequestDescription: "Lütfen talep ile ilgili detayları doldurunuz",
  finished: "Tamamlanmış",
  finishedAt: "{0} sonlandı",
  followUser: "Takip Et",
  fromDate: "Başlangıç Tarihi",
  fulfilledValue: "Gönderilen Ürün Değeri",
  fulfillments: "Gönderimler",
  fulfillmentsByStore: "MAĞAZALARA GÖRE GÖNDERİMLER",
  fundName: "Fon Adı",
  goBack: "Geri Dön",
  goBackToDashboard: "Ana Sayfaya Geri Dön",
  grandTotal: "Genel Toplam",
  groupSalary: "Grup Brüt Maaşı",
  groupSelectedCategories: "Seçili Kategorileri Grupla",
  groupSelectedProducts: "Seçili Ürünleri Grupla",
  growthRate: "Büyüme Oranı",
  haveToVerifyAccount: "İlerlemeden önce Telegram hesabınızı sisteme bağlamalısınız.",
  hello: "Merhaba, {0}",
  honest: "Açık Sözlülük",
  inStockIn: "{0} gün içerisinde stokta",
  inTheFuture: "Gelecekte",
  in_transit: "Yolda",
  incomeHistory: "Gelir Tarihçesi",
  increaseThreshold:
    "Lütfen bu çalışanın maaş artış oranını 0 ile {0} arasında olacak şekilde aşağıya yazınız.",
  interests: "İlgi Alanları",
  inventory: "Envanter",
  invoiceDetails: "Fatura Detayları",
  invoiceNo: "Fatura No",
  label_printed: "Etiket Basıldı",
  label_purchased: "Etiket Satın Alındı",
  laser: "Lazer Markalama",
  last24Hours: "Son 24 Saat",
  lastMonth: "Bu Ay",
  lastNDays: "Son {0} Gün",
  lastQuarter: "Bu Çeyrek",
  lastUpdatedOn: "Son Güncellenme",
  lastWeek: "Bu Hafta",
  lastYear: "Bu Yıl",
  lastYearFulfillment: "Geçen Yıl Gönderilen",
  lastYearSales: "Geçen Yıl Satılan",
  leavePayment: "İzin Ücreti",
  loading: "Yükleniyor",
  loadingTargetData: "Hedef Bilgileri Yükleniyor",
  loadingTeamData: "Ekip toplanıyor",
  location: "Konum",
  location_AwaitingQualityControl: "kalite kontrol bekliyor",
  location_ExternalJob: "dış operasyonda",
  location_FactoryStock: "fabrika deposunda",
  location_ReadyForAssembly: "montaj bekliyor",
  location_Shipment: "yolda",
  location_Warehouse: "mağaza deposunda",
  login: "Giriş Yap",
  mail: "Email At",
  managerPresentationTexts: "Yönetici Sunum Yazısı",
  marketing: "Pazarlama",
  matchStatus: "Eşleşme",
  materialCost: "Hammadde Maliyeti",
  max: "{0} alabileceği en büyük değerden daha büyük bir değerde",
  maxWaitTime: "En uzun bekleme süresi: {0} gün",
  maxYearlySystemIncome: "Yıllık Sistem Geliştirici Kazanç Limiti",
  merchantFees: "Satıcı Komisyonları",
  messages: "Mesajlar",
  min: "{0} alabileceği en küçük değerden daha küçük bir değerde",
  minSalaryTarget: "Minimum Maaş Hedefi",
  missingRejectionReason: "Eksik reddetme sebebi",
  modifyClause: "Maddeyi Düzenle",
  month: "ay",
  monthly: "Aylık",
  monthlyCost: "Aylık Maliyet",
  monthlyEstimate: "Tahmini Aylık Ortalama Gelir",
  monthlyMinimumFulfillmentTarget: "Aylık Gönderim Hedefi",
  monthlyMinimumSalesTarget: "Aylık Satış Hedefi",
  months: "ay",
  multipleBornToday: "doğdular",
  myProducts: "Ürünlerim",
  myReports: "Raporlarım",
  nDays: "{0} gün",
  nProducts: "{0} ürün",
  netNoticePay: "Net İhbar Tazminatı",
  netSeverancePay: "Net Kıdem Tazminatı",
  new: "Yeni",
  newItem: "Yeni {0}",
  newVersionAvailable: "Sitenin yeni bir versiyonu mevcut.",
  next: "Sonraki",
  noAccountFound: "Geçerli bir Telegram hesabı bulunamadı!",
  noCaseSelected: "Seçili Vaka Yok",
  noCategoriesSelected: "Seçili Kategori Yok",
  noClausesHere: "Oylama bekleyen madde yok",
  noComment: "Yorum Yok",
  noNotifications: "Yeni duyuru yok.",
  noProductsSelected: "Seçili Ürün Yok",
  noReport: "Rapor Yok",
  noRolesRequiringReport: "Rapor bekleyen görev tanımınız bulunamadı",
  none: "Hiçbiri",
  notes: "Notlar",
  noticePayTaxes: "İhbar Tazminatı Damga Vergisi",
  noticePeriod: "İhbar Süresi",
  old: "Eski",
  options: "Seçenekler",
  orderCount: "Sipariş Adedi",
  orderDate: "Sipariş Tarihi",
  orderNo: "Sipariş No",
  other: "Diğer",
  out_for_delivery: "Dağıtıma Çıkmış",
  overTarget: "{0} üstünde",
  overall: "Genel",
  overtime: "Fazla Veya Eksik Mesai",
  passive: "Pasif",
  past: "Geçmiş Doğum Günleri",
  pause: "Durdur",
  payInvoice: "Faturayı Öde",
  payroll: "{0} Bordro",
  payrollHistory: "Gelir Tarihçesi",
  percentDescription:
    "Meclis üyelerinin herhangi bir konu üzerinde 0 ile 100 arasında oy vererek karar almasını sağlayan oylama türü",
  personalSystemDeveloperFund: "Sistem Geliştirici Geliri",
  phoneNumber: "Telefon Numarası",
  planning: "Planlama Aşamasında",
  pleaseAddClauses: "Lütfen yeni bir madde ekleyin ya da mevcut maddeleri düzenleyin",
  pleaseEnterOrderNo: "Lütfen sorgulamak istediğiniz sipariş numarasını giriniz",
  pleaseWait: "Lütfen bekleyiniz...",
  pointDistribution: "Puan Dağılımı",
  position: "Görev Tanımı",
  potentialIncome: "Mevcut Ayın Sistem Geliştirici Kazancı",
  previous: "Önceki",
  product: "Ürün",
  productName: "Ürün Adı",
  productQuantity: "Ürün Adedi",
  productSaleStatistics: "Ürün Satış İstatistikleri",
  productsSold: "Satış Adedi",
  profile: "Profil",
  profileDetails:
    "Profilinizi güncellemek için lütfen aşağıdaki bilgileri doldurup Kaydet tuşuna basınız",
  profitRate: "Karlılık Oranı",
  profitRates: "Karlılık Oranları",
  publish: "Yayınla",
  publishChanges: "Değişiklikleri Yayınla",
  punctual: "İş Tesliminde Dakiklik",
  purchaseDate: "Satın Alım Tarihi",
  quantityDetail: "Satılan ürün adetlerini göster",
  quarterly: "Çeyreklik",
  quarterlyMinimumFulfillmentTarget: "Çeyrek Gönderim Hedefi",
  questions: "Sorular",
  quitFromJobRole: "Bu Görev Tanımından İstifa Et",
  quitFromRole: "Görevden Ayrıl",
  rating: "Puan",
  readyStock: "Satılabilir Ürün Stoğu",
  ready_for_pickup: "Depodan Teslime Hazır",
  reasonForRejection: "Reddetme Sebebi",
  refundDate: "İade Tarihi",
  refundReason: "İade Sebebi",
  refunds: "İadeler",
  reject: "Reddet",
  rejectDescription: "Lütfen reddetme nedenini yazınız ",
  rejectRequest: "Talebi Reddet",
  rejectedCandidates: "Desteklemediğiniz Adaylar",
  relativeDetail: "Toplam satışa oranları göster",
  reload: "Sayfayı Yenile",
  remaining: "{0} kaldı",
  remainingLeaves: "Kalan İzin Günleri",
  removeEmployee: "Çalışan Çıkar",
  removeFromJobRole: "Bu Görev Tanımına Sahip Kişiyi Görevden Al",
  removeFromRole: "Görevden Al",
  replacementRate: "Değişim Oranı",
  reportSuccessfullyAdded: "Raporunuz sisteme başarıyla kaydedilmiştir.",
  reports: "Raporlar",
  requestDetails: "Talep Detayları",
  requestProduct: "Ürünü Talep Et",
  requestedSalary: "Talep Edilen Brüt Maaş",
  requests: "Taleplerim",
  required: "{0} gereklidir.",
  reservedFor: "{0} ürün {1} için ayrılmış",
  result: "Sonuç",
  retract: "Geri Çek",
  review: "Değerlendirme",
  roleDetails: "Görev Tanımı Detayları",
  roleName: "Görev Tanımı",
  roles: "Görev Tanımları",
  salary: "Brüt Maaş",
  salaryGroup: "Maaş Grubu",
  salaryIncrease: "Maaş Artışı",
  salarySubsidy: "Maaş Kesintisi",
  saleStatistics: "Site Satış İstatistikleri {0}",
  sales: "Satışlar",
  salesByStore: "MAĞAZALARA GÖRE SATIŞLAR",
  salesCharts: "Satış Grafikleri",
  salesDistribution: "{0} Satış Dağılımları",
  salesGrowthRate: "Satış Büyüme Oranı",
  salesNecessary: "Çeyrek Sonuna Kadar Satılması Gereken",
  salesRatio: "Satış Oranı",
  salesValue: "Satış Değeri",
  satisfactionRates: "Memnuniyet Oranı",
  save: "Kaydet",
  search: "Ara",
  searchClauses: "DNA'da Ara",
  selectProductsToCheckIn: "Lütfen teslim aldığınız tüm ürünleri seçiniz.",
  selectProductsToCheckOut: "Lütfen teslim ettiğiniz tüm ürünleri seçiniz.",
  send: "Gönder",
  sendCheckInRequest: "Ürünleri Teslim Al",
  sendCheckOutRequest: "Ürünleri Teslim Et",
  sendRequest: "Talebi Gönder",
  serialNo: "Seri No",
  serialNumber: "Seri No",
  settings: "Ayarlar",
  settingsSuccessfullyUpdated: "Ayarlar başarıyla güncellendi",
  severancePayTaxes: "Kıdem Tazminatı Damga Vergisi",
  share: "Ortaklık Geliri",
  shareIncome: "Ortaklık Geliri",
  sharingInformation: "Bilgide Paylaşımcılık",
  shiftEnded: "Çalışma başarıyla sonlandırıldı.",
  shiftStarted: "Çalışma başarıyla başlatıldı.",
  shipmentStatistics: "Son Ayın Gönderim İstatistikleri",
  showAll: "Tüm {0} için tıklayın.",
  showMore: "Daha Fazla Göster",
  showMyVotes: "Oylarımı Göster",
  showQuantities: "Adetleri Göster",
  showRelative: "Yüzdesel Göster",
  signout: "Çıkış yap",
  spendingCharts: "Harcama Grafikleri",
  start: "Başlat",
  startShift: "Çalışmayı Başlat",
  startVerification: "Hesabımı bağla",
  statistics: "İstatistikler",
  storeProductName: "Mağaza Ürün Adı",
  storeStock: "Mağaza Stoğu",
  submit: "Gönder",
  successfullyAdded: "Yeni bir {0} başarıyla eklendi.",
  successfullyCheckedIn: "Ürünler başarıyla teslim alındı.",
  successfullyCheckedOut: "Ürünler başarıyla teslim edildi.",
  successfullyDeleted: "{0} başarıyla silindi.",
  successfullyEdited: "{0} başarıyla güncellendi.",
  successfullyExtended: "Süre başarıyla uzatıldı",
  summary: "Özet",
  systemBuilderText: "Sistem Geliştirici Katkıları",
  systemDeveloper: "Sistem Geliştiricilik",
  systemDeveloperPercent: "Sistem Geliştirici Oranı",
  target: "Hedef",
  targets: "Hedefler",
  taxes: "Vergiler",
  team: "Ekip",
  threading: "Dişli Çekimi",
  timeSpentInCompany: "Firmada Geçirdiği Süre",
  title: "Sistem hatası",
  toBeFinishedOn: "Planlanan Bitiş Tarihi",
  toDate: "Bitiş Tarihi",
  today: "Bugün Doğanlar",
  todaysBirthdays: "Bugün Doğanlar",
  total: "Toplam",
  totalCost: "Toplam Maliyet",
  totalDiscount: "İndirim",
  totalExtraIncome: "Toplam Ek Gelir",
  totalFulfillments: "{0} GÖNDERİM DEĞERİ",
  totalGross: "Final Brüt",
  totalNoticePay: "Toplam İhbar Tazminatı",
  totalOrders: "Toplam Sipariş Adedi",
  totalProfit: "Toplam Kar",
  totalRefunds: "İade Bedeli",
  totalSales: "Toplam Satış",
  totalSalesFrom: "{0} SATIŞ DEĞERİ",
  totalSeverance: "Toplam Tazminat",
  totalSeverancePay: "Toplam Kıdem Tazminatı",
  totalSystemDeveloperFund: "Toplam Sistem Geliştirici Havuzu",
  totalValue: "Toplam Değer",
  totalWeight: "Toplam Ağırlık",
  transferAmount: "Talep Edilen Transfer Miktarı",
  transferFundExplanation: "Lütfen transfer etmek istediğiniz miktarı ve sebebini giriniz",
  transferFundToSavings: "Tasarruf Fonuna Transfer Et",
  transferToFund: "Fona Transfer Et",
  transportAllowance: "Yol Yardımı",
  unaccept: "Bu Maddeyi Kaldır",
  unacceptClause: "Maddeyi Kaldır",
  undecided: "Kararsız",
  unfollowUser: "Takipten Çıkar",
  unfulfilledOrders: "Müşteriye Gönderilmeyi Bekleyen",
  unknownError: "Bilinmeyen bir hata oluştu.",
  upcoming: "Yaklaşan Doğum Günleri",
  uploadBankRecord: "Yeni Banka Kaydı Yükle",
  uploadFile: "Bir dosya yükleyin",
  upsFileReady: "Dosya indirilmek için hazırdır.",
  upsQueries: "UPS Sorguları",
  userName: "Çalışan Adı",
  userPresentationText: "Çalışanın Sunum Yazısı",
  validPhone: "{0} geçerli bir telefon numarası değildir",
  value: "Değer",
  verified: "Hesap başarıyla eşleştirildi.",
  viewStock: "Stoğu Görüntüle",
  waitingControl: "Kontrol Bekliyor",
  weWillContactYou: "Talebinizi yerine getirebildiğimiz an sizinle iletişime geçeceğiz.",
  weekOf: "{0} haftası",
  weekly: "Haftalık",
  weightFormat: "{0} kg",
  wholesaleCalculator: "Toplu Satış Fiyat Hesap Makinesi",
  willFinish: "{0} sonlanacak",
  willRemain: "{0} kalacak",
  willStart: "{0} başlayacak",
  withdraw: "Çek",
  withdrawMoney: "Hesaptan Para Çek",
  withdrawMoneyDetails: "Lütfen bu hesaptan çekmek istediğiniz para miktarını giriniz",
  year: "yıl",
  yearly: "Yıllık",
  yearlyGrowth: "Yıllık Büyüme",
  years: "yıl",
  youAreUnauthorized: "Bu sayfaya erişiminiz bulunmamakta.",
  yourProfile: "Profiliniz",
  yourVotes: "Kullandığınız Oylar",
  zendeskCases: "Zendesk Vakaları",
};

export default tr;
