import Axios from "axios";
import { dequal } from "dequal";
import { useCallback } from "react";
import useSWR from "swr";
import useStorage from "./useStorage";

const axiosFetcher = <TResponse>(url: string): Promise<TResponse | null | undefined> =>
  Axios.get<TResponse | null | undefined>(url).then((res) => res.data);

export default function useAxios<TResponse>(
  url: string | null,
  config?: {
    interval?: number;
    onSuccess?: (data: TResponse | null | undefined) => void;
    revalidateOnFocus?: boolean;
    retryOnError?: boolean;
    storage?: Storage | null;
  }
): {
  data: TResponse | null | undefined;
  mutate: (data: TResponse | null | undefined, shouldRevalidate?: boolean) => void;
  error: Error | undefined;
  triggerRefresh: () => void;
} {
  const interval = config?.interval;

  const storage = config?.storage === undefined ? sessionStorage : config.storage;

  const {
    data: localData,
    remove,
    save,
  } = useStorage<TResponse>(`cache-${url ?? ""}`, {
    storage: url === null ? null : storage,
  });

  const revalidateOnFocus = config?.revalidateOnFocus ?? true;
  const retryOnError = config?.retryOnError ?? true;

  const { data, error, mutate } = useSWR<TResponse | null | undefined, Error>(url, axiosFetcher, {
    fallbackData: localData,
    revalidateOnMount: true,
    refreshInterval: interval,
    errorRetryCount: retryOnError ? 5 : 0,
    revalidateOnFocus: revalidateOnFocus,
    shouldRetryOnError: retryOnError,
    compare: dequal,
    onSuccess: (responseData) => {
      if (url === null) return;

      if (responseData) save(responseData);
      else remove();

      if (responseData) config?.onSuccess?.(responseData);
    },
    onError: () => {
      if (url === null) return;
      remove();
    },
  });

  const transformedData = typeof data === "string" && data.length === 0 ? null : data;

  const triggerRefresh = useCallback((): void => {
    void mutate(data, true);
  }, [data, mutate]);

  return { data: transformedData, mutate, error, triggerRefresh };
}
