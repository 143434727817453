import { useCallback, useEffect, useState } from "react";
import { tryParseJSON } from "../util/json";

export default function useStorage<T>(
  key: string,
  config?: { storage?: Storage | null }
): { data: T | null | undefined; save: (data: T) => void; remove: () => void } {
  const storage = config?.storage ?? localStorage;
  const [localData, setLocalData] = useState<T | undefined>(() =>
    tryParseJSON<T>(storage.getItem(key))
  );

  useEffect(() => {
    setLocalData(tryParseJSON<T>(storage.getItem(key)));
  }, [key, storage]);

  const save = useCallback(
    (data: T): void => {
      setLocalData(data);
      const jsonString = JSON.stringify(data);
      if (jsonString.length < 100000) storage.setItem(key, jsonString);
    },
    [key, storage]
  );

  const remove = useCallback((): void => {
    setLocalData(undefined);
    storage.removeItem(key);
  }, [key, storage]);

  if (config?.storage === null) {
    return {
      data: null,
      save: (): void => {
        // do nothing
      },
      remove: (): void => {
        // do nothing
      },
    };
  }
  return { data: localData, save, remove };
}
