import type { ReactElement } from "react";
import type { RouteProps } from "react-router-dom";
import PrivateRouteWithProps from "./PrivateRouteWithProps";

function PrivateRoute({
  component: Component,
  constrained,
  roles,
  ...rest
}: RouteProps & { roles?: string[]; constrained?: boolean }): ReactElement {
  return (
    <PrivateRouteWithProps
      component={Component}
      constrained={constrained}
      roles={roles}
      {...rest}
      props={undefined}
    />
  );
}

export default PrivateRoute;
