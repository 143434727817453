import { init as initSentry, reactRouterV5Instrumentation } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { render } from "react-dom";
import App from "./App";
import "./tailwind.css";

const history = createBrowserHistory();

initSentry({
  dsn: "https://de154a3df48a442ebb97d23b97fae5c4@o255286.ingest.sentry.io/5437734",
  release: `manage-client@${process.env["npm_package_version"] ?? ""}`,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1,
});

render(<App />, document.querySelector("#root"));
