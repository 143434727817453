import clsx from "clsx";
import type { FC } from "react";
import styles from "./FoldingCubeLoader.module.css";

const FoldingCubeLoader: FC<{ text?: string }> = ({ text }) => {
  return (
    <div aria-label="loading" className="flex flex-col justify-center w-full h-full py-48">
      <div className={styles["foldingCube"]}>
        <div className={styles["cube"]}></div>
        <div className={clsx(styles["cube2"], styles["cube"])}></div>
        <div className={clsx(styles["cube4"], styles["cube"])}></div>
        <div className={clsx(styles["cube3"], styles["cube"])}></div>
      </div>

      {text}
    </div>
  );
};

export default FoldingCubeLoader;
