import { XCircleIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import type { FC } from "react";

const ErrorMessageBox: FC<{ title: string; body?: string; className?: string }> = ({
  body,
  className,
  title,
}) => {
  return (
    <div className={clsx("p-4 rounded-md bg-red-50", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="w-5 h-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium leading-5 text-red-800">{title}</h3>
          {body ? <div className="mt-2 text-sm leading-5 text-red-700">{body}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessageBox;
