export const tryParseJSON = <T>(jsonString: string | null): T | undefined => {
  if (jsonString === null) return;

  try {
    const o: unknown = JSON.parse(jsonString);

    if (o !== null) {
      return o as T;
    }
  } catch {
    return;
  }

  return;
};
