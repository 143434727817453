import { ExclamationIcon, RefreshIcon } from "@heroicons/react/outline";
import { captureException, setUser as setSentryUser, withProfiler } from "@sentry/react";
import type { AxiosError } from "axios";
import Axios from "axios";
import type { FC, ReactElement } from "react";
import { StrictMode, useCallback, useEffect, useMemo, useState } from "react";
import apiConfig from "./api.config";
import AppContainer from "./AppContainer";
import SimpleModal from "./components/modals/SimpleModal";
import useAxios from "./hooks/useAxios";
import strings from "./locales/strings";
import type Auth from "./types/interfaces/user";

const handleWheelEvent = (event: WheelEvent): void => {
  if ((document.activeElement as HTMLInputElement).type === "number") {
    event.preventDefault();
  }
};

const App: FC = (): ReactElement => {
  const { data: auth, mutate: mutateAuth, triggerRefresh: revalidateAuth } = useAxios<
    Auth | undefined
  >("auth/me", {
    onSuccess: (data) => {
      if (data) {
        setSentryUser({ email: data.user.email });

        const storedOffset = data.user.timezoneOffset;
        const realOffset = new Date().getTimezoneOffset();

        if (storedOffset !== realOffset) {
          Axios.post(`/auth/timezone/${realOffset}`, {}).catch((err) => {
            captureException(err);
          });
        }
      }
    },
    revalidateOnFocus: false,
    retryOnError: false,
    storage: localStorage,
  });

  const signOut = useCallback((): void => {
    mutateAuth(null, false);
  }, [mutateAuth]);

  useEffect(() => {
    document.addEventListener("wheel", handleWheelEvent);
    return (): void => {
      document.removeEventListener("wheel", handleWheelEvent);
    };
  }, []);

  //Force redirect to new hostname
  useEffect(() => {
    if (window.location.host === "beta.manage.edelkrone.com") {
      window.location.href =
        "https://manage.edelkrone.com" + window.location.pathname + window.location.search;
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{ message: string; show: boolean }>({
    show: false,
    message: "",
  });

  if (auth) {
    strings.setLanguage(auth.user.locality);
  }

  Axios.defaults.baseURL = apiConfig.baseURL;
  Axios.defaults.withCredentials = true;

  Axios.interceptors.request.use(
    (config) => {
      if (config.method !== "get" && config.method !== "GET") setLoading(true);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.response.config.url?.startsWith("auth/")) signOut();
      } else if (error.response) {
        setErrorMessage({
          show: true,
          message: error.response.data ? JSON.stringify(error.response.data) : strings.unknownError,
        });
      }

      setLoading(false);
      throw error;
    }
  );

  const authContextState = useMemo(() => {
    return {
      auth: auth,
      revalidate: revalidateAuth,
      language: auth?.user.locality ?? "tr",
      serverTimeOffset: auth?.serverTimezoneOffset ?? 180,
    };
  }, [auth, revalidateAuth]);
  return (
    <StrictMode>
      <SimpleModal
        body={strings.pleaseWait}
        icon={<RefreshIcon className="w-10 h-10 text-gray-600" />}
        show={loading}
        title={strings.loading}
        zIndexClass="z-40"
      />
      <SimpleModal
        body={errorMessage.message}
        close={(): void => {
          setErrorMessage((error) => ({
            show: false,
            message: error.message,
          }));
        }}
        icon={<ExclamationIcon className="w-8 h-8 text-red-600" />}
        iconClass="bg-red-100"
        show={errorMessage.show}
        title={strings.error}
        zIndexClass="z-50"
      />
      <AppContainer {...authContextState} />
    </StrictMode>
  );
};

export default withProfiler(App);
