import { LightBulbIcon } from "@heroicons/react/solid";
import type { FC } from "react";
import React, { useEffect } from "react";
import SimpleModal from "./components/modals/SimpleModal";
import strings from "./locales/strings";
import * as serviceWorker from "./serviceWorker";

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration): void => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate, onRegister: onSWUpdate });
  }, []);

  const reloadPage = (): void => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <SimpleModal
      buttonClicked={reloadPage}
      buttonText={strings.reload}
      close={(): void => {
        setShowReload(false);
      }}
      icon={<LightBulbIcon className="w-8 h-8 text-green-600" />}
      iconClass="bg-green-100 h-14 w-14"
      show={showReload}
      title={strings.newVersionAvailable}
      zIndexClass="z-30"
    />
  );
};

export default ServiceWorkerWrapper;
