/* eslint-disable max-lines */
import type LocaleStrings from "./localeStrings";

const en: LocaleStrings = {
  Active: "In Progress",
  Finished: "Finished",
  Pending: "Pending",
  abstain: "Abstain",
  accept: "Accept",
  acceptedCandidates: "Candidates you find suitable for this position",
  accountStatement: "Account Statement",
  activate: "Activate",
  activateAnnouncement: "Activate Announcement",
  active: "In Progress",
  activeRemoteEmployees: "Active Remote Employees",
  add: "Add",
  addCategory: "Add Category",
  addColumn: "Add New Category",
  addData: "Add New Data",
  addEmployee: "Add Employee",
  addItem: "Add {0}",
  addNewClause: "Add New Clause",
  addNewCustomerCase: "Add New Customer Case",
  addNewPurchaseRDRequest: "Add New Purchase R&D Request",
  addNewPurchaseRequest: "Add New Purchase Request",
  addNewRole: "Add New Role",
  addNewServiceRequest: "Add New Service Request",
  addNewSoftwareRequest: "Add New Software Request",
  addNote: "Add Note",
  allFunds: "ALL FUNDS",
  allProducts: "All Products",
  allStores: "All Stores",
  amount: "Amount",
  and: " and ",
  announcements: "Announcements",
  approve: "Approve",
  askAnswerQuestionsHere: "You can ask & answer the questions using the form below",
  askQuestion: "Post Question",
  assignJob: "Assign Job",
  assignJobDescription: "Please choose the employee to assign the job",
  attempted_delivery: "Attempted Delivery",
  availableIncome: "Account Balance",
  averagePrice: "Average Product Price",
  averageWeightPrice: "Average Price per lb",
  awaitedAtDate: "Expected on {0}",
  awaitingApproval: "Awaiting Approval",
  awaitingClauses: "Awaiting Clauses",
  awaitingPayment: "Pending payments: {0}",
  basedOnLastYearData: "Based on last year's data",
  basisDays: "Days Basis for Calculation",
  basisSalary: "Salary Basis for Severance",
  beACandidate: "Be A Candidate",
  benefitsForUser: "Severance benefits calculated for {0}",
  birthdayMessage: "{0} was {1} today. Help them celebrate!",
  birthdays: "Birthdays",
  bloodType: "Blood Type",
  bonus: "Bonus",
  bornToday: "born",
  bundle: "Bundle",
  call: "Call",
  cancel: "Cancel",
  cancelRequest: "Cancel Request",
  cancelWarning: "You will lose your claims for this product if you cancel your request.",
  category: "Category",
  category_bundleFinder: "Sales from Bundle Detail Page or from various referred pages.",
  category_checkbox:
    "Sales from Compatible Products Section available on all product and bundle detail pages.",
  category_collection: "Sales from Product Menu or through icons under the website header.",
  category_compare: "Sales through Compare & Bundle Page.",
  category_compatibleProduct:
    "Sales through Compatible Products section located on product detail pages.",
  category_discount: "Sales through Campaign details Page.",
  category_home: "Sales from Homepage.",
  category_product: "Sales through Buy Now Section of All Products.",
  category_productModal:
    'Sales through product overview pages with more than 1 model or video loops that allow "add to cart" option.',
  category_recommendedProduct: "Sales from Recommended Products on Shopping Cart modal.",
  category_search: "Sales through Search.",
  category_selectbox: "Sales from required checkboxes on product or bundle detail pages.",
  category_tradeup: "Sales through TradeUP program",
  chart: "Chart",
  checkIn: "Check In",
  checkInProduct: "Product Check In",
  checkOut: "Check Out",
  checkOutProduct: "Product Check Out",
  checkoutDate: "Check Out Date",
  chooseCandidates: "Please choose the candidates you want to vote for",
  chooseCandidatesForEachCategory: "Please choose employees you want to vote for in each category.",
  chooseCase: "Choose a case from the list to start",
  chooseCategories: "Choose a category from the table below to start",
  chooseEmployeeToAdd: "Please choose the employee you want to add to this job",
  chooseEmployeeToRemove: "Please choose the employee you want to remove from this job",
  chooseProducts: "Choose a product from the table below to start",
  clickHereToVoteNow: "Click here to vote now.",
  coating: "Coating",
  commandOverJob: "Has Good Command over Profession",
  comparedTo: "compared to {0}",
  comparedToLastYear: "(Compared to last year)",
  comparison: "Comparison",
  compromising: "Compromises",
  confirmed: "Waiting for Pickup by Carrier",
  contactAdministrationIfMistake:
    "If you think there is a mistake, please contact the administrator.",
  contactAdministrator: "Please contact the site administrator.",
  createRequestForProduct: "Create a Request for this Product",
  createdOn: "Requested On",
  currentMonthRevenue: "Current Month's Revenue",
  currentSalary: "Current Salary",
  currentYearFulfillment: "Current Year Fulfilled",
  currentYearSales: "Current Year Sold",
  customerEmail: "Customer Email",
  customerFeedback: "Customer Feedback",
  customerList: "Customer List",
  customerOrders: "Customer Orders",
  customerProducts: "Customer Products",
  customerSatisfaction: "Customer Satisfaction",
  daily: "Daily",
  dailyAverage: "Daily Average",
  dailySalary: "Daily Salary",
  dailyTarget: "Daily Target",
  date: "Date",
  day: "day",
  days: "days",
  daysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  daysUntilArrival: "Days Until Arrival",
  deactivate: "Deactivate",
  deactivateAnnouncement: "Deactivate Announcement",
  deductAmount: "Deduct",
  deductFromSalaryFund: "Deduct from Salary Fund",
  deductionExplanation: "Please enter the amount you want to deduct from the salary fund",
  delete: "Delete",
  deleteClause: "Remove Clause",
  deleteRecord: "Delete this {0}",
  deleteRecordButton: "Delete {0}",
  deleteWarning: "Once you delete this {0}, you will lose all data associated with it",
  delivered: "Delivered",
  deliveredCount: "Delivered Count",
  departmentAdmin: "Manager",
  departmentName: "Department Name",
  description: "Description",
  details: "Details",
  director: "Director",
  discounts: "Discounts",
  discountsAndRefunds: "{0} Discounts & Refunds",
  dontBeACandidate: "Don't Be A Candidate",
  downloadFile: "Download File",
  dragDrop: "or drag and drop",
  drop: "Drop your file here",
  edit: "Edit",
  editDeadline: "Edit Deadline",
  editItem: "Edit {0}",
  editMyProfile: "Edit My Profile",
  emergencyContact: "Emergency Contact",
  emergencyContactPhoneNumber: "Emergency Contact Phone Number",
  employee: "Employee",
  employeeName: "Full Name",
  endShift: "End Shift",
  enterBankRecordDetails: "Please enter the details of the bank record.",
  enterDetails: "Please enter all details for the {0}.",
  enterDetailsForNew: "Please enter all details for the new {0}.",
  entranceDate: "Entrance Date",
  entranceText: "Presentation Text",
  error: "Error",
  errorTitle: "There was 1 error with your submission",
  errorsTitle: "There were {0} errors with your submission",
  estimated: "Estimated",
  evaluateCandidates:
    "Please read the reports of the individuals and evaluate the contribution of each employee to growth between 0-100.",
  exitDate: "Exit Date",
  expectedReturnDate: "Expected Return Date",
  experience: "Experience",
  exportToExcel: "Export to Excel",
  extendLoan: "Extend Loan",
  extendedLoan: "Loan Extended",
  externalParts: "External Parts",
  extraCost: "Other Costs",
  factoryProductName: "Factory Product Name",
  failure: "Failed",
  fileNotFound: "File not found",
  fillInPaymentDetails: "Please fill in all the details regarding the payment",
  finish: "Finish Request",
  finishDescription: "Please enter the details of the request",
  finishPurchase: "Finish Purchase",
  finishPurchaseDescription: "Please enter the details of the purchase",
  finishRequest: "Finish Request",
  finishRequestDescription: "Please enter the details of the request",
  finished: "Finished",
  finishedAt: "finished {0}",
  followUser: "Follow",
  fromDate: "From Date",
  fulfilledValue: "Fulfillments",
  fulfillments: "Fulfillments",
  fulfillmentsByStore: "FULFILLMENTS BY STORE",
  fundName: "Fund Name",
  goBack: "Go Back",
  goBackToDashboard: "Go Back To the Dashboard",
  grandTotal: "Grand Total",
  groupSalary: "Group Salary",
  groupSelectedCategories: "Group Selected Categories",
  groupSelectedProducts: "Group Selected Products",
  growthRate: "Growth Rate",
  haveToVerifyAccount: "You must verify your account before proceeding.",
  hello: "Hello, {0}",
  honest: "Honest",
  inStockIn: "In stock in {0} days",
  inTheFuture: "In the future",
  in_transit: "In Transit",
  incomeHistory: "Income History",
  increaseThreshold: "Please enter an increase rate between 0 and {0}",
  interests: "Interests",
  inventory: "Inventory",
  invoiceDetails: "Invoice Details",
  invoiceNo: "Invoice No",
  label_printed: "Label Printed",
  label_purchased: "Label Purchased",
  laser: "Laser Marking",
  last24Hours: "Last 24 Hours",
  lastMonth: "Month to Date",
  lastNDays: "Last {0} Days",
  lastQuarter: "Quarter to Date",
  lastUpdatedOn: "Last Updated On",
  lastWeek: "Week to Date",
  lastYear: "Year to Date",
  lastYearFulfillment: "Last Year Fulfilled",
  lastYearSales: "Last Year Sold",
  leavePayment: "Paid Time Off Payments",
  loading: "Posting data",
  loadingTargetData: "Loading Target Data",
  loadingTeamData: "Fetching the team",
  location: "Location",
  location_AwaitingQualityControl: "awaiting quality control",
  location_ExternalJob: "awaiting external operations",
  location_FactoryStock: "at the factory warehouse",
  location_ReadyForAssembly: "waiting for assembly",
  location_Shipment: "in transit",
  location_Warehouse: "at the warehouse",
  login: "Log In",
  mail: "Mail",
  managerPresentationTexts: "Manager's Presentation Text",
  marketing: "Marketing",
  matchStatus: "Match",
  materialCost: "Cost of Materials",
  max: "{0} is larger than the maximum value it can take",
  maxWaitTime: "Maximum wait time: {0} days",
  maxYearlySystemIncome: "Yearly Income Cap",
  merchantFees: "Merchant Fees",
  messages: "Messages",
  min: "{0} is smaller than the minimum value it can take",
  minSalaryTarget: "Minimum Salary Target",
  missingRejectionReason: "Missing reason for rejection",
  modifyClause: "Modify Clause",
  month: "month",
  monthly: "Monthly",
  monthlyCost: "Monthly Cost",
  monthlyEstimate: "Average Monthly Income Estimate",
  monthlyMinimumFulfillmentTarget: "Monthly Fulfillment Target",
  monthlyMinimumSalesTarget: "Monthly Sales Target",
  months: "months",
  multipleBornToday: "born",
  myProducts: "My Products",
  myReports: "My Reports",
  nDays: "{0} days",
  nProducts: "{0} products",
  netNoticePay: "Net Notice Payment",
  netSeverancePay: "Net Severance Payment",
  new: "New",
  newItem: "New {0}",
  newVersionAvailable: "A new version is available",
  next: "Next",
  noAccountFound: "No valid Telegram account found!",
  noCaseSelected: "No Case Selected",
  noCategoriesSelected: "No Categories Selected",
  noClausesHere: "There is nothing here",
  noComment: "No Comment",
  noNotifications: "No new notifications",
  noProductsSelected: "No Products Selected",
  noReport: "No Report",
  noRolesRequiringReport: "You have no roles requiring a report",
  none: "None",
  notes: "Notes",
  noticePayTaxes: "Notice Payment Taxes",
  noticePeriod: "Notice Period",
  old: "Old",
  options: "Options",
  orderCount: "Order Count",
  orderDate: "Order Date",
  orderNo: "Order No",
  other: "Other",
  out_for_delivery: "Out for Delivery",
  overTarget: "{0} over",
  overall: "Overall",
  overtime: "Overtime or Undertime",
  passive: "Passive",
  past: "Past Birthdays",
  pause: "Pause",
  payInvoice: "Pay Invoice",
  payroll: "{0} Payroll",
  payrollHistory: "Payroll History",
  percentDescription: "Allows members to vote from 0 to 100 on any subject",
  personalSystemDeveloperFund: "System Developer Income",
  phoneNumber: "Phone Number",
  planning: "Planning",
  pleaseAddClauses: "Please add a new clause or modify an existing clause",
  pleaseEnterOrderNo: "Please enter the order number for your query",
  pleaseWait: "Please wait...",
  pointDistribution: "Point Distribution",
  position: "Position",
  potentialIncome: "Current Month's Income",
  previous: "Previous",
  product: "Product",
  productName: "Product Name",
  productQuantity: "Quantity",
  productSaleStatistics: "Product Sale Statistics",
  productsSold: "Products Sold",
  profile: "Profile",
  profileDetails: "Please enter the details below and click Save to update your profile",
  profitRate: "Profit Rate",
  profitRates: "Profit Rates",
  publish: "Publish",
  publishChanges: "Publish Changes",
  punctual: "Punctual",
  purchaseDate: "Purchase Date",
  quantityDetail: "Show products sold rather than sales",
  quarterly: "Quarterly",
  quarterlyMinimumFulfillmentTarget: "Quarterly Fulfillment Target",
  questions: "Questions",
  quitFromJobRole: "Quit from this Role",
  quitFromRole: "Quit from Role",
  rating: "Rating",
  readyStock: "Stock Ready to Be Sold",
  ready_for_pickup: "Ready for Pickup",
  reasonForRejection: "Reason for Rejection",
  refundDate: "Refund Date",
  refundReason: "Refund Reason",
  refunds: "Refunds",
  reject: "Reject",
  rejectDescription: "Please enter the details of the reject",
  rejectRequest: "Eject Issue",
  rejectedCandidates: "Candidates you do not find suitable for this position",
  relativeDetail: "Show percentage of total sales",
  reload: "Reload Page",
  remaining: "{0} remaining",
  remainingLeaves: "Remaining Paid Time Off Days",
  removeEmployee: "Remove Employee",
  removeFromJobRole: "Remove the Current Holder from this Position",
  removeFromRole: "Remove from Role",
  replacementRate: "Replacement Rate",
  reportSuccessfullyAdded: "Your report has successfully been added to the system.",
  reports: "Reports",
  requestDetails: "Request Details",
  requestProduct: "Request Product",
  requestedSalary: "Requested Salary",
  requests: "My Requests",
  required: "{0} is required",
  reservedFor: "{0} reserved for {1}",
  result: "Result",
  retract: "Retract",
  review: "Review",
  roleDetails: "Job Details",
  roleName: "Role",
  roles: "Roles",
  salary: "Salary",
  salaryGroup: "Salary Group",
  salaryIncrease: "Salary Increase",
  salarySubsidy: "Subsidized Salary",
  saleStatistics: "{0} Sales Statistics",
  sales: "Sales",
  salesByStore: "SALES BY STORE",
  salesCharts: "Sales Charts",
  salesDistribution: "{0} Sales Distribution",
  salesGrowthRate: "Sales Growth Rate",
  salesNecessary: "Total Amount of Sales Necessary",
  salesRatio: "% of Total",
  salesValue: "Value",
  satisfactionRates: "Satisfaction Rate",
  save: "Save",
  search: "Search",
  searchClauses: "Search in DNA",
  selectProductsToCheckIn: "Please select all products that you are checking in.",
  selectProductsToCheckOut: "Please select all products that you are checking out.",
  send: "Send",
  sendCheckInRequest: "Check Products In",
  sendCheckOutRequest: "Check Products Out",
  sendRequest: "Send Request",
  serialNo: "Serial #",
  serialNumber: "Serial #",
  settings: "Settings",
  settingsSuccessfullyUpdated: "Settings successfully updated",
  severancePayTaxes: "Severance Payment Taxes",
  share: "Share Income",
  shareIncome: "Share Income",
  sharingInformation: "Shares Information",
  shiftEnded: "Shift ended successfully.",
  shiftStarted: "Shift started successfully.",
  shipmentStatistics: "Shipment Statistics for the Last Month",
  showAll: "Click here to show all {0}.",
  showMore: "Show More",
  showMyVotes: "Show My Votes",
  showQuantities: "Show Quantities",
  showRelative: "Show Percentages",
  signout: "Sign out",
  spendingCharts: "Spending Charts",
  start: "Start",
  startShift: "Start Shift",
  startVerification: "Start Verification",
  statistics: "Statistics",
  storeProductName: "Store Product Name",
  storeStock: "Store Stock",
  submit: "Submit",
  successfullyAdded: "A new {0} was successfully created.",
  successfullyCheckedIn: "Products successfully checked in.",
  successfullyCheckedOut: "Products successfully checked out.",
  successfullyDeleted: "{0} was successfully deleted.",
  successfullyEdited: "{0} was successfully edited.",
  successfullyExtended: "Your loan was successfully extended",
  summary: "Summary",
  systemBuilderText: "Systems Developed",
  systemDeveloper: "Develops new Systems",
  systemDeveloperPercent: "System Developer Rate",
  target: "Target",
  targets: "Targets",
  taxes: "Taxes",
  team: "Team",
  threading: "Threading",
  timeSpentInCompany: "Time Spent in Company",
  title: "Something went wrong",
  toBeFinishedOn: "Planning to Finish On",
  toDate: "To Date",
  today: "Today's Birthdays",
  todaysBirthdays: "Today's Birthdays",
  total: "Total",
  totalCost: "Total Cost",
  totalDiscount: "Discount",
  totalExtraIncome: "Total Extra Income",
  totalFulfillments: "FULFILLMENTS FROM {0}",
  totalGross: "Total Gross",
  totalNoticePay: "Total Notice Payment",
  totalOrders: "Total Orders",
  totalProfit: "Total Profit",
  totalRefunds: "Refunds",
  totalSales: "Total Sales",
  totalSalesFrom: "SALES FROM {0}",
  totalSeverance: "Total Severance",
  totalSeverancePay: "Total Severance Payment",
  totalSystemDeveloperFund: "Total System Developer Fund",
  totalValue: "Total Value",
  totalWeight: "Total Weight",
  transferAmount: "Requested Transfer Amount",
  transferFundExplanation:
    "Please enter the amount you want to transfer and the reason for this request",
  transferFundToSavings: "Transfer Fund to Savings",
  transferToFund: "Request Transfer to Fund",
  transportAllowance: "Transport Allowance",
  unaccept: "Remove This Clause",
  unacceptClause: "Remove Clause",
  undecided: "Undecided",
  unfollowUser: "Unfollow",
  unfulfilledOrders: "Total Value of Unfulfilled Orders",
  unknownError: "An unknown error has occured.",
  upcoming: "Upcoming Birthdays",
  uploadBankRecord: "Upload New Bank Record",
  uploadFile: "Upload a file",
  upsFileReady: "The file is ready for download",
  upsQueries: "UPS Queries",
  userName: "User Name",
  userPresentationText: "Employee's Presentation Text",
  validPhone: "{0} is not a valid phone number",
  value: "Value",
  verified: "Account successfully verified.",
  viewStock: "View Stock",
  waitingControl: "Waiting for control",
  weWillContactYou: "We will contact you when we can fulfill your request.",
  weekOf: "Week of {0}",
  weekly: "Weekly",
  weightFormat: "{0} lb",
  wholesaleCalculator: "Wholesale Pricing Calculator",
  willFinish: "will finish {0}",
  willRemain: "{0} will remain",
  willStart: "will start {0}",
  withdraw: "Withdraw",
  withdrawMoney: "Withdraw Money",
  withdrawMoneyDetails: "Please fill in the amount you want to withdraw from this account",
  year: "year",
  yearly: "Yearly",
  yearlyGrowth: "Yearly Growth",
  years: "years",
  youAreUnauthorized: "You are not authorized to view this page",
  yourProfile: "Your Profile",
  yourVotes: "Your Votes",
  zendeskCases: "Zendesk Cases",
};

export default en;
